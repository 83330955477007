* {
  outline: none !important;
}

html {
  font-size: 85.5%;
  font-family: "Quicksand";
  background-color: #262933;
}

body {
  font-size: 14px;
  line-height: 1.4;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps>.ps__rail-y,
.ps>.ps__rail-x {
  z-index: 99;
}

a[role=button] {
  text-decoration: none;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 100px) {
  ::-webkit-scrollbar {
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    border-radius: 20px;
  }

  .card-table-general {
    border-radius: 12px !important;
    overflow-x: scroll !important;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 1000px !important;
    overflow-y: scroll !important;
  }

  .card-table-examfinish {
    border-radius: 20px !important;
    overflow-x: scroll !important;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 2000px !important;
    overflow-y: scroll !important;
  }

  .card-table-resultados {
    border-radius: 20px !important;
    overflow-x: scroll !important;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 820px !important;
    overflow-y: scroll !important;
  }
}




form label {
  z-index: 99;
}

.login-papa {
  display: 'flex'
}

.login {

  background-color: '#1C66D8';
  padding: "3% 0"
}

.login-background {
  height: 105%;
  background-position: center;
  background-size: cover;
  min-height: 550px;
  background-image: url('./assets/images/backgrounds/login-fondo.jpg');
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  position: relative;
  box-shadow: 3px -1px 8px 0px rgb(50, 63, 241);
}

.login-background.con::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top left, #05193799, #004d7a00, #47a9dd59, #3253588a, #baeddf66);
  z-index: -1;
  -moz-border-radius: 100%;
  -webkit-box-shadow: 0 0 20px rgba(15, 60, 83, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}


.fondo {
  height: 80vh;
  background-position: center;
  background-size: cover;
  min-height: 582px;
  background-image: url('./assets/images/backgrounds/dark-material-bg.jpg');
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  margin: 70px 43px;
  border-radius: 20px;
  position: relative;
  -moz-border-radius: 100%;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 7px 5px 9px rgb(25 118 210 / 53%);
}

.fondo.con::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right top, #05193799, #043d9399, #039be5, #85ade2);
  z-index: -1;
  -moz-border-radius: 100%;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

/*Buscar custom  de material*/
.MuiListItemButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #1976d24a;
}

a {
  text-decoration: none;
  color: black;
  font-family: "Quicksand";
  font-weight: "400",
}

.tabla-componente {
  height: 70vh;
  min-height: 582px;
  z-index: 1;
  margin: 70px 43px;
  position: relative;
}

.iniciar-sesion {
  width: 378px;
  height: 40px;
  color: "white";
  background-color: "#1565c0"
}

.iniciar-sesion:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #1565c0;
  border-color: rgb(8, 14, 208);
  border-width: 1px;
  border-style: solid;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.MuiDrawer-paperAnchorLeft {
  background-color: #0E4AC2 !important;
}

.texto-nav span {
  font-family: "Quicksand" !important;
  font-weight: 500 !important;
  font-size: 1.1rem !important;
}

.MuiTypography-root {
  font-family: "Quicksand" !important;
  font-weight: 400 !important;
  font-size: 1.5rem !important;
}

.cambio-text {
  color: "white"
}

.boton {
  width: 24.5ch !important;
  height: 4.4ch !important;
  background-color: rgb(14 74 194) !important;
  font-family: "Quicksand" !important;
  font-size: 1.15rem !important;
}

.boton:hover {
  width: 24.5ch !important;
  height: 4.4ch !important;
  font-family: "Quicksand" !important;
  font-size: 1.15rem !important;
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(14 74 194);
  border-color: rgb(8, 14, 208);
  border-width: 1px;
  border-style: solid;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.boton-icon {
  width: 5.0ch !important;
  height: 4.8ch !important;
  background-color: #095CB4 !important;
}

.boton-icon:hover {
  width: 5.0ch !important;
  height: 4.8ch !important;
  background-color: #095CB4 !important;
  border-color: rgb(1, 1, 1);
  border-width: 1px;
  border-style: solid;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.cardHome {
  overflow-x: scroll !important;
  width: 100% !important;
}

.cardHome-bienvenida {
  font-weight: 400 !important;
  font-size: 3rem !important;
}

.cardHome-direccion {
  font-weight: 400 !important;
  font-size: 1.38rem !important;
}


.card-table {
  border-radius: 12px !important;
  overflow-x: scroll !important;
  margin-right: auto !important;
  margin-left: auto !important;
  margin-top: 80px !important;
}

.card-table-textField {
  border-radius: 12px !important;
  overflow-x: scroll !important;
  margin-right: auto !important;
  margin-left: auto !important;
}



tr {
  height: 40px !important;
}

th {
  max-width: 125px;
  min-width: 125px;
  overflow-x: auto;
}

.textfield-buscar {
  padding-right: 100px;
  padding-left: 50px;
  min-width: 500px !important;
}


.textfield-buscar-combo {
  padding-right: 20px;
  padding-left: 0px;
  min-width: 330px !important;
}

.textfield-combo {
  padding-right: 20px;
  padding-left: 20px;
  min-width: 100px
}



.text-responsive-combo {
  font-size: 1.5rem !important;
}
.text-responsive-edit {
  font-size: 2.2rem !important;
}


@page { 
  size: A4 
}

@media print {
  .pagebreak {

    page-break-after: always !important;
  }

  .pagebreak2 {
    page-break-inside: avoid;
  }

  .no-break-inside {
    
    break-inside: "avoid";
  }

  .break-before {
    
    break-before: "always";
  }

  .footer {
    position: fixed;
    bottom: 0;
  }

  .pdf-header {
    position: absolute; 
    top: 2;
    left: 0;
    right: 0;
  }
  
}

@media print {
  .pdf-header {
    position: static;  /* Esto desactiva la posición fija solo para la impresión */
  }
  /* Otros estilos específicos de impresión que necesites */
}


@media only screen and (max-width : 600px){
  .contenedor-tabla-apilis {
    display: inline-block !important;
  }
}

@media only screen and (max-width : 700px){
  .contenedor-tabla-apilis1 {
    display: inline-block !important;
  }

  .contenedor-tabla-configure {
    display: inline-block !important;
  }
}

@media only screen and (max-width : 800px){
  .contenedor-tabla-apilis1 {
    display: inline-block !important;
  }
}


@media only screen and (max-width : 800px){
  .contenedor-tabla-apilis2 {
    display: inline-block !important;
  }
}

@media only screen and (min-width : 200px) and (max-width : 400px){ 
.card-table-general {
  border-radius: 12px !important;
  overflow-x: scroll !important;
  margin-right: auto !important;
  margin-left: auto !important;
  width: 310px !important;
  max-height: 100% !important;
}
}

.card-container:hover {
   transform: scale(1.1);
   box-Shadow: 0px 0px 15px 0px;
}


.task-container {
  /* border: 1px dotted pink; */
  margin: 0;
  list-style: none;
  padding: 5px;
  border-radius: 5px;
  font-size: 2rem;

  display:inline;
  flex-direction: column;
}

.task-item {
  color: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px #4a4c4e;
  border-radius: 5px;
  padding: 0.3em 0.5em;
  background-color: rgb(71, 34, 207);
  text-align: center;
  margin: 5px;
  border: 2px solid transparent;
}

.task-item:focus {
  outline: none;
  border: 2px solid #4a9af5;
}






@media only screen and (max-width : 1000px) {
  .login-background.con {
    display: none !important;
  }

  .login {
    height: auto;
    background-color: '#1C66D8';
    padding: "3% 0";
  }

  .card-table {
    border-radius: 5px !important;
    overflow-x: scroll !important;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 280px !important;
    max-height: 480px !important;
  }

  .card-table-general {
    border-radius: 5px !important;
    overflow-x: scroll !important;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 600px !important;
    max-height: 100% !important;
  }

  .card-table-apilis3 {
    border-radius: 5px !important;
    overflow-x: scroll !important;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 400px !important;
    max-height: 100% !important;
  }

  .nav-tabla {
    display: flex;
    flex-direction: column;
  }

  .textfield-buscar {
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 200px !important;
  }

  .boton-icon {
    width: 6.0ch !important;
    height: 4.8ch !important;
    background-color: #3077c2 !important;
  }

  .cardHome {
    overflow-y: scroll !important;
    max-width: 280px !important;
  }

  .cardHome-bienvenida {
    padding-top: 80%;
    font-weight: 400 !important;
    font-size: 1.4rem !important;
    min-width: 350px;
  }

  .cardHome-direccion {
    padding-top: 50%;
    font-weight: 400 !important;
    font-size: 1.2rem !important;
    min-width: 300px;
  }


  .nav-tabla-comboText {
    flex-direction: column !important;
    justify-items: left;
  }

  .textfield-buscar-combo {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 10px;
    padding-bottom: 20px;
    min-width: 280px !important;
  }

  .textfield-combo {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 20px;
    min-width: 280px !important;
  }
  .text-responsive-combo {
    font-size: 1.3rem !important;
  }
  .text-responsive-edit {
    font-size: 2rem !important;
  }
}